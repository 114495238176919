import { Typography, Box } from "@mui/material";
import { isCustomRule, RuleType } from "src/types/Workflow";
import RuleFilter from "./WorkflowFilter";

interface Props {
  rule: RuleType;
}

/* Adapted from RuleItem.tsx in the dashboard-frontend repo */
const Rule = ({ rule }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <Typography sx={{ color: "black", fontWeight: "bold", ml: 1, mt: 1 }}>
        {`${rule?.outcomes}`}
      </Typography>
      <Typography
        variant="h6"
        sx={{ color: "grey", fontSize: 12, ml: 1, mt: 1.25 }}
      >
        IF
      </Typography>
      <RuleFilter
        filters={isCustomRule(rule) ? [] : rule.filter.filters}
        isCustom={isCustomRule(rule)}
        query={isCustomRule(rule) ? rule.query : ""}
      />
    </Box>
  );
};

export default Rule;
