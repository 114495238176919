import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import PhonelinkOutlinedIcon from "@mui/icons-material/PhonelinkOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import WebAssetOutlinedIcon from "@mui/icons-material/WebAssetOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";

export function IconByIdentifier(props: { identifier: string }) {
  const { identifier } = props;
  const style = { fontSize: "20px", mr: 1 };
  if (identifier.includes("accountId")) {
    return <PermIdentityOutlinedIcon sx={style} />;
  }
  if (identifier.includes("ip")) {
    return <RoomOutlinedIcon sx={style} />;
  }
  if (identifier.includes("printId")) {
    return <PhonelinkOutlinedIcon sx={style} />;
  }
  if (identifier.includes("email")) {
    return <MailOutlineOutlinedIcon sx={style} />;
  }
  if (identifier.includes("phone")) {
    return <PhoneOutlinedIcon sx={style} />;
  }
  if (identifier.includes("userAgent")) {
    return <WebAssetOutlinedIcon sx={style} />;
  }
  if (identifier.includes("lists")) {
    return <FormatListBulletedIcon sx={style} />;
  }
  if (identifier.includes("risk")) {
    return <WarningAmberOutlined sx={style} />;
  }
  return <AccessTimeIcon sx={style} />;
}
