import {
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from "@mui/material";
import WorkflowList from "./WorkflowList";
import { useGetClientRulesQuery } from "src/lib/api";
import { useHandleError } from "src/lib/errors";

interface Props {
  clientUUID: string;
}

const WorkflowsCard = ({ clientUUID }: Props) => {
  const {
    data: workflowData,
    error: getWorkflowsError,
    isLoading: isGetWorkflowsLoading,
  } = useGetClientRulesQuery(clientUUID);
  useHandleError(getWorkflowsError, "Failed to retrieve workflows");

  const hasWorkflows = workflowData && workflowData?.length > 0;

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h3">Client Workflows</Typography>}
      />
      <CardContent>
        {isGetWorkflowsLoading && <LinearProgress />}
        {!hasWorkflows && !isGetWorkflowsLoading && (
          <Typography sx={{ pt: 1 }}>Client has no workflows</Typography>
        )}
        {hasWorkflows && !isGetWorkflowsLoading && (
          <WorkflowList workflows={workflowData} />
        )}
      </CardContent>
    </Card>
  );
};

export default WorkflowsCard;
