import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useGetDatadogLogsQuery } from "src/lib/api";
import { useHandleError } from "src/lib/errors";

const capitalize = (text: string): string => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

interface LogTableProps {
  emptyText: string;
  logs:
    | {
        timestamp: Date;
        service: string;
        message: string;
      }[]
    | undefined;
  isLoading: boolean;
}

const LogTable = ({ emptyText, logs, isLoading }: LogTableProps) => {
  const renderSkeletonRows = () => {
    const skeletonRows = Array.from({ length: 5 });
    return skeletonRows.map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton variant="text" width="80%" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="60%" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="90%" />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Timestamp</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Service</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            renderSkeletonRows()
          ) : logs === undefined || logs.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} align="center">
                {emptyText}
              </TableCell>
            </TableRow>
          ) : (
            logs.map((log, index) => (
              <TableRow key={index}>
                <TableCell>{log.timestamp.toISOString()}</TableCell>
                <TableCell>{log.service}</TableCell>
                <TableCell>{log.message}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface Props {
  clientUUID: string;
}

const MostRecentLogsCard = ({ clientUUID }: Props) => {
  const env = process.env.REACT_APP_ENV === "prod" ? "prod" : "dev";

  const logsGetQuery =
    `filter[from]=now-7d&filter[query]=` +
    encodeURIComponent(
      `@clientUUID:${clientUUID} @env:${env} service:(signalprint OR rules-service OR events-service OR client-services-api) status:(error OR warn)`
    );
  const {
    data: serviceLogData,
    isLoading: isGetServiceLogsLoading,
    error: serviceLogsError,
  } = useGetDatadogLogsQuery(logsGetQuery);
  useHandleError(serviceLogsError, "Failed to retrieve service logs");
  const serviceLogs = serviceLogData?.data
    ?.map((item) => ({
      message: capitalize(item.attributes.message),
      service: capitalize(item.attributes.service),
      timestamp: new Date(item.attributes.timestamp),
    }))
    .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

  const errorGetQuery =
    `filter[from]=now-7d&filter[query]=` +
    encodeURIComponent(`@user_error:true @clientUUID:${clientUUID}`);
  const {
    data: userErrorData,
    isLoading: isGetUserErrorLogsLoading,
    error: userErrorLogsError,
  } = useGetDatadogLogsQuery(errorGetQuery);
  useHandleError(userErrorLogsError, "Failed to retrieve user error logs");

  const userErrorLogs = userErrorData?.data
    ?.map((item) => ({
      message: capitalize(item.attributes.message),
      service: capitalize(item.attributes.service),
      timestamp: new Date(item.attributes.timestamp),
    }))
    .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

  const now = Math.floor(Date.now());
  const today = new Date();
  const sevenDaysAgo = new Date(today.setDate(today.getDate() - 7));
  const weekAgo = Math.floor(sevenDaysAgo.getTime());

  const datadogUserErrorURL = `https://app.datadoghq.com/logs?query=%40user_error%3Atrue%20%40clientUUID%3A${clientUUID}&agg_m=count&agg_m_source=base&agg_t=count&cols=host%2Cservice&fromUser=true&messageDisplay=inline&refresh_mode=sliding&storage=hot&stream_sort=desc&viz=stream&from_ts=${weekAgo}&to_ts=${now}&live=false`;
  const datadogLogsURL = `https://app.datadoghq.com/logs?query=%40clientUUID%3A${clientUUID}%20%40env%3Aprod%20service%3A%28signalprint%20OR%20rules-service%20OR%20events-service%20OR%20client-services-api%29%20status%3A%28error%20OR%20warn%29&agg_m=count&agg_m_source=base&agg_t=count&cols=host%2Cservice&fromUser=true&messageDisplay=inline&refresh_mode=sliding&storage=hot&stream_sort=desc&viz=stream&from_ts=${weekAgo}&to_ts=${now}&live=true`;

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h3">Most Recent Logs</Typography>}
      />

      <CardContent>
        <Typography variant="h4" gutterBottom>
          User Errors
        </Typography>
        <LogTable
          emptyText="No Recent User Errors"
          logs={userErrorLogs}
          isLoading={isGetUserErrorLogsLoading}
        />
        <Typography sx={{ pt: 1 }} variant="body1">
          For more information{" "}
          <a
            href={datadogUserErrorURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </a>
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          Logs From Customer Facing Services
        </Typography>
        <LogTable
          emptyText="No Recent Service Logs"
          logs={serviceLogs}
          isLoading={isGetServiceLogsLoading}
        />
        <Typography sx={{ pt: 1 }} variant="body1">
          For more information{" "}
          <a href={datadogLogsURL} target="_blank" rel="noopener noreferrer">
            click here
          </a>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MostRecentLogsCard;
