import { Typography, Box, Card, Chip, useTheme } from "@mui/material";
import { IconByIdentifier } from "./iconByIdentifier";
import { Filter } from "src/types/Workflow";
interface Props {
  filters?: Filter[];
  isCustom: boolean;
  query?: string;
}

/* Adapted from PrettyFilter.tsx in the dashboard-frontend repo */
const WorkflowFilter = ({ filters, isCustom, query }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {!isCustom &&
        filters?.map((filter) => (
          <Card key={filter.id} sx={{ mb: 1.5, ml: 2, p: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              <Box sx={{ mt: 0.25, mr: -0.75 }}>
                <IconByIdentifier identifier={filter.field} />
              </Box>
              <Typography
                variant="body2"
                sx={{ color: "black", fontWeight: "bold", mt: 0.25 }}
              >
                {filter.field}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "grey", fontStyle: "italic", mt: 0.25 }}
              >
                {filter.operator?.toLowerCase()}
              </Typography>
              {filter?.values?.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{
                    borderRadius: 1,
                    fontWeight: "bold",
                    fontSize: 12,
                    backgroundColor: theme.palette.background.default,
                  }}
                />
              ))}
            </Box>
          </Card>
        ))}
      {isCustom && (
        <Box>
          <Card sx={{ mb: 2, ml: 2, p: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              <Typography variant="body2">{query}</Typography>
            </Box>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default WorkflowFilter;
